import CustomApolloProvider from 'components/ApolloProvider';
import { GlobalAppProvider } from 'context/appContext';
import { AuthProvider } from 'context/authContext';
import { ReloaderProvider } from 'context/reloadContext';
import { SnackbarProvider } from 'context/snackbarContext';
import { ViewportProvider } from 'context/viewportContext';
import { useTranslation } from 'lib/hooks/useTranslation';
import moment from 'moment';
import 'moment/locale/de';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';
import 'styles/globals.css';
import 'swiper/css/bundle';

function MyApp({ Component, pageProps }: AppProps) {
    const loadingRef = useRef<unknown | any>(null);

    const router = useRouter();
    const t = useTranslation();

    moment.locale(router.locale);

    useEffect(() => {
        const handleRouteChange = () => {
            try {
                loadingRef.current.continuousStart();
            } catch (err) {}
        };

        const handleRouteComplete = () => {
            try {
                loadingRef.current.complete();
            } catch (err) {}
        };

        router.events.on('routeChangeStart', handleRouteChange);
        router.events.on('routeChangeComplete', handleRouteComplete);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
            router.events.off('routeChangeComplete', handleRouteComplete);

            try {
                loadingRef.current.complete();
            } catch (err) {}
        };
    }, []);

    return (
        <>
            <Head>
                <title>{t.seo.title}</title>
                <meta name="title" content={t.seo.title} />
                <meta name="description" content={t.seo.description} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://ubiscore.com/" />
                <meta property="og:title" content={t.seo.title} />
                <meta property="og:description" content={t.seo.description} />
                <meta property="og:image" content="https://cdn.ubiscore.com/images/open-graph-card-default.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://ubiscore.com/" />
                <meta property="twitter:title" content={t.seo.title} />
                <meta property="twitter:description" content={t.seo.description} />
                <meta property="twitter:image" content="https://cdn.ubiscore.com/images/open-graph-card-default.png" />
            </Head>
            <GlobalAppProvider>
                <AuthProvider>
                    <CustomApolloProvider>
                        <ViewportProvider>
                            <ReloaderProvider>
                                <SnackbarProvider>
                                    <LoadingBar color="#4285DE" height={1} ref={loadingRef} />
                                    <Component {...pageProps} />
                                </SnackbarProvider>
                            </ReloaderProvider>
                        </ViewportProvider>
                    </CustomApolloProvider>
                </AuthProvider>
            </GlobalAppProvider>
        </>
    );
}

export default MyApp;
