import * as React from 'react';

const ViewportContext = React.createContext<{
    reload: boolean;
    handleReload: () => void;
}>({
    reload: false,
    handleReload: () => null,
});

export const ReloaderProvider = ({ children }: { children: React.ReactNode }) => {
    const [reload, setReload] = React.useState(false);

    const handleReload = () => {
        setReload(!reload);
    };
    return <ViewportContext.Provider value={{ reload, handleReload }}>{children}</ViewportContext.Provider>;
};

export const useReloader = () => {
    const { reload, handleReload } = React.useContext(ViewportContext);

    return { reload, handleReload };
};
