import { ApolloProvider } from '@apollo/client';
import { useApollo } from 'lib/hooks/useApollo';
import { ReactNode } from 'react';

const CustomApolloProvider = ({ children }: { children: ReactNode }) => {
    const apolloClient = useApollo();

    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default CustomApolloProvider;
