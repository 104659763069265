// global context for saving global/app level preferences

import { createContext, ReactNode, useContext, useReducer } from 'react';

type GlobalAppAction = { type: 'setHotjarInitiailzed'; payload: boolean };

type Dispatch = (action: GlobalAppAction) => void;

type GlobalAppState = {
    hotjarInitialized: boolean;
};

type GlobalAppProviderProps = {
    children: ReactNode;
};

const reducer = (state: GlobalAppState, action: GlobalAppAction): GlobalAppState => {
    switch (action.type) {
        case 'setHotjarInitiailzed':
            return { ...state, hotjarInitialized: action.payload };
        default:
            return state;
    }
};

const initialState = {
    hotjarInitialized: false,
};

const GlobalAppContext = createContext<{ state: GlobalAppState; dispatch: Dispatch } | undefined>(undefined);

const GlobalAppProvider = ({ children }: GlobalAppProviderProps) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = { state, dispatch };

    return <GlobalAppContext.Provider value={value}>{children}</GlobalAppContext.Provider>;
};

const useGlobalApp = () => {
    const context = useContext(GlobalAppContext);

    if (context === undefined) {
        throw new Error('useGlobalApp must be used within a GlobalAppProvider');
    }

    return context;
};

export { useGlobalApp, GlobalAppProvider };
