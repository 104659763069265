export enum Routes {
    Signup = '/signup',
    SignupComplete = '/signup/complete',
    SignupSuccess = '/signup/thank-you',
    Login = '/login',
    RecoverPassword = '/recover',
    Dashboard = '/dashboard',
    DashboardRemediations = '/dashboard/remediations',
    OrganizationDomains = '/dashboard/domains',
    OrganizationDomainReport = '/dashboard/domains/[domainId]/report-overview',
    OrganizationReports = '/dashboard/domains/[domainId]/reports',
    OrganizationSettings = '/dashboard/settings',
    OrganizationUsers = '/dashboard/users',
    OrganizationSubscription = '/dashboard/subscription',
    OrganizationPricing = '/dashboard/subscription/pricing',
    OrganizationBadges = '/dashboard/badges',
    OrganizationGdpr = '/dashboard/gdpr',
    ProviderLogin = '/provider/login',
    ProviderRecoverPassword = '/provider/recover',
    ProviderDashboard = '/provider/dashboard',
    ProviderDashboardOrganizationDomains = '/provider/dashboard/organization/domains',
    ProviderDashboardOrganizationReports = '/provider/dashboard/organization/domains/[domainId]/reports',
    ProviderDashboardRemediations = '/provider/dashboard/remediations',
    ProviderDashboardOrganization = '/provider/dashboard/organization',
    ProviderDashboardOrganizationReport = '/provider/dashboard/organization/domains/[domainId]/report-overview',
    ProviderAdmins = '/provider/admins',
    ProviderRemediations = '/provider/remediations',
    ProviderVendors = '/provider/vendors',
    ProviderDomains = '/provider/domains',
    ProviderTechnologies = '/provider/technologies',
    ProviderISP = '/provider/isp',
    ProviderUnclaimed = '/provider/unclaimed',
    ProviderDashboardOrganizationGdpr = '/provider/dashboard/organization/domains/[domainId]/report-overview/gdpr',
}

export enum StaticRoutesEN {
    TermsOfService = 'https://ubiscore.com/terms-of-service',
    PrivacyPolicy = 'https://ubiscore.com/privacy-policy',
    PrivacyTips = 'https://www.ubiscore.com/blog/top-5-privacy-tips-for-startups-that-are-easy-to-do/',
}

export enum StaticRoutesDE {
    TermsOfService = 'https://ubiscore.com/de/terms-of-service',
    PrivacyPolicy = 'https://ubiscore.com/de/privacy-policy',
    PrivacyTips = 'https://www.ubiscore.com/de/blog/top-5-einfach-umzusetzende-datenschutz-tipps-fuer-startups/',
}
