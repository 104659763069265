import { SupportedLocales } from 'constants/i18n';
import { LocalStorageKeys } from 'constants/localstorage';

export const UseLocalStorage = () => {
    const isBrowser = ((): boolean => {
        return typeof localStorage !== 'undefined';
    })();

    const getItem = (key: string): string => {
        if (isBrowser) {
            return localStorage[key];
        }
        return '';
    };

    const getProfileData = () => {
        const stateString = getItem(LocalStorageKeys.ProfileState);
        if (!stateString) {
            return '';
        }
        try {
            const state = JSON.parse(stateString);
            return state?.provider ? state?.provider : state?.user;
        } catch {
            return '';
        }
    };

    const getToken = (): string => {
        const stateString = getItem(LocalStorageKeys.ProfileState);
        if (!stateString) {
            return '';
        }
        try {
            const state = JSON.parse(stateString);
            return state?.token;
        } catch {
            return '';
        }
    };

    const setLanguage = (language: SupportedLocales): void => {
        localStorage.setItem(LocalStorageKeys.Language, language);
    };

    const getLanguage = (): string => {
        return getItem(LocalStorageKeys.Language);
    };

    const clearStorage = () => {
        if (isBrowser) {
            localStorage.clear();
        }
    };

    const setFirstLogin = (): void => {
        localStorage.setItem(LocalStorageKeys.FirstLogin, 'false');
    };

    const getFirstLogin = (): string | null => {
        return localStorage.getItem(LocalStorageKeys.FirstLogin);
    };

    return { getToken, clearStorage, getProfileData, setLanguage, getLanguage, setFirstLogin, getFirstLogin };
};
