import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { Routes } from 'constants/navigation';
import { AuthAtion, useAuth } from 'context/authContext';
import { envConfig } from 'lib/utils/env';
import { isClient } from 'lib/utils/generic';
import router, { NextRouter, useRouter } from 'next/router';
import { Dispatch, useMemo } from 'react';
import { UseLocalStorage } from './useLocalStorage';

const getLocale = () => {
    if (!isClient) return 'en';
    return router.locale;
};

const authMiddleware = (authToken: string) => {
    return new ApolloLink((operation, forward) => {
        if (authToken) {
            operation.setContext({
                headers: {
                    authorization: `Bearer ${authToken}`,
                    'Accept-Language': getLocale(),
                },
            });
        }

        return forward(operation);
    });
};

// this logic already handles the token validation upon requests
const errorLink = (dispatch: Dispatch<AuthAtion>, clearStorage: () => void, router: NextRouter) => {
    return onError(({ graphQLErrors }) => {
        if (graphQLErrors && graphQLErrors.find((error) => error.message === 'Unauthorized')) {
            dispatch({
                type: 'logout',
            });
            clearStorage();
            router.push(Routes.Login);
        }
    });
};

export const useApollo = () => {
    const { getToken, clearStorage } = UseLocalStorage();
    const { dispatch } = useAuth();
    const router = useRouter();
    const token = getToken();

    // const httpLink = new HttpLink({ uri: envConfig.UBISCORE_API_URL });
    const uploadLink = createUploadLink({ uri: envConfig.UBISCORE_API_URL });

    const cache = new InMemoryCache({});

    const apolloClient = useMemo(
        () =>
            new ApolloClient({
                link: authMiddleware(token)
                    .concat(errorLink(dispatch, clearStorage, router))
                    .concat(uploadLink as unknown as ApolloLink),
                cache,
            }),
        [token],
    );

    return apolloClient;
};
