import { UbiSnackbar } from 'components/common/elements/UbiSnackbar';
import { createContext, ReactNode, useContext, useReducer } from 'react';

type Dispatch = (action: SnackbarAction) => void;

type SnackbarSeverity = 'error' | 'success';

type State = {
    visible: boolean;
    message: string;
    severity?: 'error' | 'success';
};

type SnackbarAction = { type: 'setMessage'; payload: string; severity?: SnackbarSeverity } | { type: 'setVisible'; payload: boolean };

const initialState: State = {
    visible: false,
    message: '',
    severity: 'error',
};

const snackbarReducer = (state: State, action: SnackbarAction) => {
    switch (action.type) {
        case 'setMessage':
            return { ...state, message: action.payload, severity: action?.severity };
        case 'setVisible':
            return { ...state, visible: action.payload };
        default:
            throw new Error(`Unhandled action type`);
    }
};

type SnackbarProviderProps = {
    children: ReactNode;
};

const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
    const [state, dispatch] = useReducer(snackbarReducer, initialState);

    const value = { state, dispatch };

    return (
        <SnackbarContext.Provider value={value}>
            <UbiSnackbar
                open={state.visible}
                message={state.message}
                severity={state.severity || 'error'}
                handleClose={() => {
                    dispatch({ type: 'setVisible', payload: false });
                }}
            />
            {children}
        </SnackbarContext.Provider>
    );
};

const SnackbarContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

const useSnackbar = () => {
    const context = useContext(SnackbarContext);

    if (context === undefined) {
        throw new Error('useSnackbar must be used within SnackbarProvider');
    }

    return context;
};

export { useSnackbar, SnackbarProvider };
