import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Image from 'next/image';

interface Props {
    message: string;
    open: boolean;
    severity: 'error' | 'success';
    handleClose: () => void;
}

export const UbiSnackbar = ({ message, open, severity = 'error', handleClose }: Props) => {
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert
                sx={{
                    ['.MuiAlert-message']: {
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    },
                }}
                icon={
                    severity === 'error' ? (
                        <Image alt="error icon" src="/images/alert-icon.svg" height="36px" width="27px" />
                    ) : (
                        <Image alt="success icon" src="/images/icon-success.svg" height="36px" width="27px" />
                    )
                }
                severity={severity}
                onClose={handleClose}
                style={{
                    display: 'flex',
                    padding: '10px 17px',
                    border: severity === 'error' ? '1px solid #F64A4A' : '1px solid #08E5A7',
                    borderRadius: '10px',
                }}>
                {message}
            </Alert>
        </Snackbar>
    );
};
