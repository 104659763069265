import { LocalStorageKeys } from 'constants/localstorage';
import { createContext, ReactNode, useContext, useReducer } from 'react';

type Dispatch = (action: AuthAtion) => void;

type User = {
    email: string;
    verified: boolean;
    _id: string;
};

type Organization = {
    role: 'owner' | 'member';
    _id: string;
};

type Provider = {
    _id: string;
    name: string;
    email: string;
};

type State = {
    token: string | undefined | null;
    expiresIn: number;
    refreshToken: string | null;
    user: User | null;
    organizations?: Organization[];
    provider?: Provider;
};

export type AuthAtion = { type: 'login'; payload: State } | { type: 'logout' };

const initialState: State = {
    token: null,
    user: null,
    refreshToken: null,
    organizations: [],
    provider: undefined,
    expiresIn: 0,
};

const authReducer = (state: State, action: AuthAtion) => {
    switch (action.type) {
        case 'login':
            localStorage.setItem(LocalStorageKeys.ProfileState, JSON.stringify(action.payload));
            return { ...action.payload };
        case 'logout':
            localStorage.removeItem(LocalStorageKeys.ProfileState);
            return { ...initialState };
        default: {
            throw new Error(`Unhandled action type`);
        }
    }
};

type AuthProviderProps = {
    children: ReactNode;
};

const AuthContext = createContext<{ state: State; dispatch: Dispatch; isLoggedIn: () => boolean; isProvider: () => boolean } | undefined>(undefined);

const AuthProvider = ({ children }: AuthProviderProps) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    const restoreProfileFromStorage = () => {
        const getPersistedState = () => {
            const persistedStateString = localStorage.getItem(LocalStorageKeys.ProfileState);
            try {
                if (persistedStateString) {
                    return JSON.parse(persistedStateString);
                }
            } catch {
                localStorage.clear();
                return null;
            }
        };

        const persistedState = getPersistedState();

        if (persistedState) {
            dispatch({
                type: 'login',
                payload: persistedState,
            });
            return true;
        }
        return false;
    };

    const isLoggedIn = () => {
        const isLoginPersisted = restoreProfileFromStorage();
        return !!state.token || isLoginPersisted;
    };

    const isProvider = () => {
        return !!state.provider;
    };

    const value = { state, dispatch, isLoggedIn, isProvider };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }

    return context;
};

export { AuthProvider, useAuth };
